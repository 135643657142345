// Components
import custColorPicker from '@/components/standalone/cust-color-picker/Cust-color-picker.vue'

/**
 * Vue declaration ------------------------------------
 */

// Name
const name = 'Colors-mj-text'

// Vue@Properties
const props = {
  mjml: Object
}
// Vue@Properties

// Vue@Components
const components = {
  custColorPicker
}
// Vue@Components

// Vue component syntax
export default {
  name,
  props,
  components
}
